:root {
  --feature-color: #ff9900;
  --card-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  --transition-smooth: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.home-page-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  animation: fadeIn .5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes gradientAnimation {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

.hero-section {
  padding: 48px 40px;
  color: #000;
  border-radius: 8px;
  margin: 24px 0;
  position: relative;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  transition: var(--transition-smooth);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
}

.hero-content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.hero-left { flex: 1; }
.hero-main { margin-bottom: 32px; }

.hero-content h1 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  letter-spacing: -.5px;
  color: #fff;
}

.hero-content p {
  font-size: 16px;
  margin: 12px 0 0;
  opacity: .9;
  line-height: 1.5;
  color: #fff;
}

.environment-tag {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 4px;
  background: rgba(255,255,255,.15);
  font-weight: 500;
  margin-left: 16px;
  color: #fff;
  backdrop-filter: blur(4px);
}

.environment-switcher {
  display: flex;
  gap: 8px;
  margin-left: 24px;
  padding: 4px;
  border-radius: 6px;
  backdrop-filter: blur(4px);
}

.env-button {
  padding: 8px 16px;
  border-radius: 4px;
  transition: var(--transition-smooth);
  background: rgba(255,255,255,.1);
  border: 1px solid rgba(255,255,255,.2);
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.env-button:hover {
  background: rgba(255,255,255,.2);
  transform: translateY(-1px);
}

.env-button.active {
  background: rgba(255,255,255,.25);
  box-shadow: 0 2px 8px rgba(0,0,0,.1);
}

.env-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.main-content {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
  animation: slideUp .5s ease-out;
}

.section-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 32px;
  border-radius: 8px;
  color: #000;
  position: relative;
  box-shadow: var(--card-shadow);
}

.section-title h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -.3px;
  color: #000;
}

.section-title p {
  margin: 8px 0 0;
  opacity: .9;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  width: 100%;
}

.feature-card {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: var(--transition-smooth);
  border: 1px solid #e5e7eb;
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  inset: 0 0 auto 0;
  height: 3px;
  background: var(--feature-color);
  opacity: 0;
  transition: var(--transition-smooth);
}

.feature-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 24px rgba(0,0,0,.06);
}

.feature-card:hover::before { opacity: 1; }

.feature-card h3 {
  font-size: 18px;
  margin: 0;
  color: #111827;
  font-weight: 600;
  letter-spacing: -.3px;
}

.feature-card p {
  color: #6b7280;
  line-height: 1.6;
  margin: 0;
  font-size: 14px;
  flex-grow: 1;
}

.feature-button {
  width: 100%;
  border-radius: 4px;
  transition: var(--transition-smooth);
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  color: #111827;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
}

.feature-button:hover {
  background: var(--feature-color);
  color: #fff;
  border-color: transparent;
}

@media (prefers-color-scheme: dark) {
  .feature-card {
    background: #1f2937;
    border-color: #374151;
  }
  .feature-card h3 { color: #f3f4f6; }
  .feature-card p { color: #9ca3af; }
  .feature-button {
    background: #374151;
    border-color: #4b5563;
    color: #f3f4f6;
  }
  .hero-section {
    background: #1E293B !important;
    box-shadow: 0 4px 24px rgba(0,0,0,.2);
  }
  .hero-content h1 { color: #fff; }
  .hero-content p { color: #e5e7eb; }
  .environment-tag {
    background: rgba(255,255,255,.1);
    color: #fff;
  }
  .env-button {
    background: rgba(255,255,255,.05);
    border-color: rgba(255,255,255,.1);
    color: #fff;
  }
  .env-button:hover { background: rgba(255,255,255,.1); }
  .env-button.active {
    background: rgba(255,255,255,.15);
    box-shadow: 0 2px 8px rgba(0,0,0,.2);
  }
  .section-header {
    background: #1E293B !important;
    color: #fff;
  }
  .section-title h2 { color: #fff; }
  .section-title p { color: #e5e7eb; }
  .feature-card:hover { box-shadow: 0 12px 24px rgba(0,0,0,.2); }
  .feature-button:hover {
    background: var(--feature-color);
    color: #fff;
    border-color: transparent;
  }
}

@media (max-width: 1200px) {
  .cards-grid { grid-template-columns: repeat(2, 1fr); }
}

@media (max-width: 992px) {
  .hero-content {
    flex-direction: column;
    gap: 24px;
  }
  .environment-switcher {
    width: 100%;
    justify-content: flex-start;
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .home-page-container { padding: 0 16px; }
  .hero-section {
    padding: 24px 20px;
    margin: 16px 0;
  }
  .cards-grid { grid-template-columns: 1fr; }
  .hero-content h1 {
    font-size: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .environment-tag { margin-left: 0; }
  .section-header { padding: 20px; }
  .section-title h2 { font-size: 20px; }
  .feature-card { padding: 20px; }
}

@media (max-width: 480px) {
  .home-page-container { padding: 0 12px; }
  .hero-section { padding: 20px 16px; }
  .hero-content h1 { font-size: 22px; }
  .environment-switcher {
    flex-direction: column;
    gap: 8px;
  }
  .env-button {
    width: 100%;
    justify-content: center;
  }
  .section-header { padding: 16px; }
  .section-title h2 { font-size: 18px; }
  .section-title p { font-size: 13px; }
  .feature-card h3 { font-size: 16px; }
  .feature-card p { font-size: 13px; }
}

@media (hover: none) and (pointer: coarse) {
  .feature-button, .env-button {
    min-height: 44px;
    padding: 12px 16px;
  }
}

@media print {
  .hero-section {
    background: none !important;
    box-shadow: none;
    border: 1px solid #ccc;
  }
  .feature-card {
    break-inside: avoid;
    border: 1px solid #ccc;
    box-shadow: none;
  }
  .feature-button { display: none; }
}

html { scroll-behavior: smooth; }

@media (min-width: 1400px) {
  .home-page-container { max-width: 80vw; }
}

.env-gradient {
  background: #FF6101;
}

.section-header {
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.3);
}

.toggle-switch {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle-switch span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.toggle-switch span.active {
  color: white;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #FF9900;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
