@font-face {
  font-family: 'Amazon Ember';
  src: url("https://fonts.workshops.aws/webfonts/AmazonEmber_W_Bd.eot");
  src: url("https://fonts.workshops.aws/webfonts/AmazonEmber_W_Bd.eot?#iefix") format("embedded-opentype"), url("https://fonts.workshops.aws/webfonts/AmazonEmber_W_Bd.woff2") format("woff2"), url("https://fonts.workshops.aws/webfonts/AmazonEmber_W_Lt.woff") format("woff");
  font-style: bold;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Amazon Ember", "Work Sans", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  color: var(--MAIN-TEXT-color) !important;
}

code {
  font-family: Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
}
