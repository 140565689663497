.toggle-switch {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggle-switch span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.toggle-switch span.active {
  color: white;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #FF9900;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch input:disabled + .slider {
  opacity: 0.5;
  cursor: not-allowed;
}
